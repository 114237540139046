.lognIn {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  padding-bottom: 40px;

  .loginHeader {
    h2 {
      margin: 50px 0 20px 20px;
    }

    .headerWelcome {
      display: flex;
      margin: 0 0 50px 20px;
      color: gray;
    }
  }

  h2 {
    display: flex;
    margin: 50px 0 50px 20px;
    font-size: 30px;
  }

  .loginFieldContainer{
    position: relative;
  }

  .logInFormField {
    margin-bottom: 40px;

    label {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .logInlabelHeading {
        text-align: left;
        width: 90vw;
        margin: 0;
        font-weight: 500;
      }

      .loginPasswordContainer{
        position: relative;
      }

      input {
        display: flex;
        width: 90vw;
        height: 5vh;
        border-radius: 4px;
        border-width: 1px;
        border: 1px solid #d3d3d3;
        font-size: 15px;

        &:focus {
          outline: none;
          border-color: #888;
        }
      }
    }

    .OtpText{
      position: absolute;
      right: 30px;
      top: 5.5vw;
      font-size: 13px;
      font-weight: 600;
      cursor: pointer;
      color: #009eab;
      width: fit-content;
    }

    .loginEyeIcon{
      position: absolute;
      right: 15px;
      top: 10px;
      width: 25px;
      height: 25px;
      cursor: pointer;
    }
  }

  .logInFormCheckBox {
    label {
      display: flex;
      gap: 10px;
      width: fit-content;
      align-items: center;
      margin-left: 10px;
      margin-bottom: 20px;
      cursor: pointer;

    }

    input {
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
  }

  .centerButtonWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    .logInButton {
      display: flex;
      width: 80%;
      justify-content: center;
      align-items: center;
      height: 6vh;
      border-radius: 30px;
      border: none;
      background-color: #009eab;
      color: white;
      font-size: 17px;
      font-weight: 500;
      cursor: pointer;
    }
  }

  .separator-container {
    display: flex;
    align-items: center;
    width: 100%;
    margin: 20px 0;
  }

  .line {
    flex-grow: 1;
    height: 1px;
    background-color: #d3d3d3;
  }

  .separator-text {
    margin: 0 10px;
    font-size: 14px;
    color: #9e9e9e;
    text-align: center;
  }

  .navigateToLogIn {
    color: gray;
    display: flex;
    justify-content: center;
    margin-top: 50px;
    padding-bottom: 30px;
    gap: 5px;

    span {
      color: #596be6;
      cursor: pointer;
    }
  }

  .separator-container {
    display: flex;
    align-items: center;
    width: 100%;
    margin: 20px 0;
  }

  .line {
    flex-grow: 1;
    height: 1px;
    background-color: #d3d3d3;
  }

  .separator-text {
    margin: 0 10px;
    font-size: 14px;
    color: #9e9e9e;
    text-align: center;
  }

  .navigateToSignIn {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    color: #596be6;
    margin-top: 50px;
    cursor: pointer;
  }

  .error{
    margin: 0;
    color: red;
    font-size: 14px;
    margin-left: 20px;
  }
}
