.quantitySpinner{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    padding-bottom: 30px;
    border-top-left-radius: 30px; 
    border-top-right-radius: 30px;
    box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.1); 
    background-color: white;

    .spinnerheader{
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
        
        p{
            text-align: center;
            margin: 0;
            padding: 0;
        }

        .quantityText{
            font-size: 12px;
            color: #009EAB;
        }
    }

    .spinner{
        display: flex;
        flex-direction: row;
        width: 60%;
        background-color: #009EAB;
        justify-content: space-between;
        align-items: center;
        padding: 10px;
        border-radius: 50px;

        .minusBtn, .plusBtn{
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: white;
            color: #009EAB;
            font-size: 50px;
            border-radius: 100px;
            width: 13vw;
            height: 7vh;
            cursor: pointer;
        }

        .spinnerValue{
            color: white;
            font-size: 30px;
        }
    }
}