.assignWrapper{
  position: relative;
  height: 100vh;

  .assign {
    width: 100%;
    height: 91vh;
    overflow: auto;
    max-height: 91vh;
    background-color: #f7f7f9;
    border-bottom-left-radius: 100px;
    border-bottom-right-radius: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    padding-bottom: 100px;
  
    .assignHeader {
      width: 100%;
      display: flex;
      align-items: center;
      padding-top: 20px;
      padding-left: 20px;
      gap: 20px;
      box-sizing: border-box;
  
      .assignBack {
        width: 25px;
        height: 25px;
        cursor: pointer;
      }
  
      .searchBar {
        width: 100%;
        position: relative;
  
        .searchbarContainer {
          display: flex;
          // justify-content: center;
          align-items: center;
          width: 100%;
  
          .searchBarInput {
            width: 90%;
            height: 4vh;
            border-radius: 4px;
            border-width: 1px;
            border: 1px solid #d3d3d3;
            font-size: 15px;
  
            &:focus {
              outline: none;
              border-color: #888;
            }
  
            &::placeholder {
              padding-left: 5px;
              color: #888;
            }
          }
  
          .assignIcon {
            position: absolute;
            right: 70px;
            width: 25px;
            height: 25px;
            cursor: pointer;
          }
  
          .micIcon {
            position: absolute;
            right: 36px;
            width: 25px;
            height: 25px;
            cursor: pointer;
          }
        }
      }
    }
  
    .assignPoolDetails {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding-left: 8vw;
      margin-top: 5vw;
      box-sizing: border-box;
  
      .assignPoolName {
        width: 73%;
        font-size: 16px;
        font-weight: 600;
      }
  
      .assignPoolAddress {
        width: 70%;
        font-size: 12px;
      }
    }
  
    .assignDivider {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      width: 96%;
  
      .assignLine {
        flex: 1;
        border: none;
        border-top: 1px solid #009EAB;
      }
  
      .assignDividerText {
        color: #009EAB;
        font-size: 16px;
        white-space: nowrap;
      }
    }
  
    .cutomerDetailsWrapper{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 30px;
      padding-top: 20px;
      padding-bottom: 20px;
  
      .cutomerDetails {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 30px;
          margin: 0 !important;
          padding: 0 !important;
      
          .nameRow {
            display: flex;
            width: 100%;
            padding: 0px 20px;
            flex-direction: row;
            justify-content: space-between;
            align-content: center;
            margin: 0;
      
            .assignRowHead{
              font-weight: 500;
              font-size: 15px;
            }
      
            .assignRowBody{
              font-size: 14px;
            }
      
            .assignNumber{
              display: flex;
              justify-content: center;
              align-items: center;
              gap: 5px;
      
              img{
                  width: 20px;
                  height: 20px;
                  cursor: pointer;
              }
            }
          }
        }
    }
  
    .assignToBtn{
      position: absolute;
      bottom: 24vw;
      
      p{
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: #009EAB;
          padding: 10px 33vw;
          color: white;
          font-weight: 500;
          border-radius: 7px;
          cursor: pointer;
      }
    }
  
    .assignBottomNavBar {
      position: absolute;
      bottom: 0;
      width: 100%;
      background-color: white;
    }
  }

  .popUpContaine{
    position: absolute;
    bottom: 0;
    height: 50vh;
  }
}
