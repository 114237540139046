.merchantBottomNavBar {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.navBarTabs {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between; 
    align-items: center; 
}

.navBarTabs img {
    flex: 1; 
    max-width: 100%;
    padding: 0 10px; 
    box-sizing: border-box; 
    cursor: pointer;
}
