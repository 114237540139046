.technicians{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    padding-bottom: 30px;
    background-color: white;
    border-top-left-radius: 30px; 
    border-top-right-radius: 30px;
    box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.1); 

    .techniciansHeader{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 10px;

        p{
            margin: 0;
        }

        .heading{
            font-weight: 500;
        }

        .techniciansText{
            font-size: 12px;
            color: #009EAB;
        }
    }

    .techniciansFlexBox{
        display: flex;
        gap: 10vw;
        width: 100%;
        max-height: 100vh; 
        overflow-y: auto; 
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 20px;
        padding: 10px; 
        box-sizing: border-box;
        padding-bottom: 70px;

        .techniciansBox{
            display: flex;
            flex-direction: column;
            background-color: white;
            color: #009EAB;
            border: 1px solid #009EAB;
            border-radius: 4px;
            padding: 10px;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            width: 30%;
            
            p{
                margin: 0;
            }
        }

        .techniciansBox1{
            display: flex;
            flex-direction: column;
            background-color: #009EAB;
            color: white;
            border: 1px solid #009EAB;
            border-radius: 4px;
            padding: 10px;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            width: 30%;
            
            p{
                margin: 0;
            }
        }
    }

    .btnContainer{
        position: fixed;
        bottom: 0;
        width: 100%;

        .backAndNextBtn{
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            margin: 0;
    
            .backBtn{
                width: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                border: 1px solid #009EAB;
                padding: 10px 25vw 10px 25vw;
                margin: 0;
                color: #009EAB;
                font-weight: 500;
                font-size: 18px;
                cursor: pointer;
                background-color: white;
            }
    
            .techniciansNextBtn{
                width: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 11px 25vw 11px 25vw;
                background-color: #009EAB;
                margin: 0;
                color: white;
                font-weight: 500;
                font-size: 18px;
                cursor: pointer;
            }
        }
    }
}