.poolInfoBox {
  margin-top: 30px;

  .poolInfoText {
    font-size: 16px;
    font-weight: 500;
    padding: 0;
    margin-left: 30px;
  }

  .poolinfo {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
    line-height: 0;
    gap: 0;

    .poolInfoImg {
      width: 200px;
      height: 120px;
      margin-bottom: 20px;
    }
  }
}
