.welcomePage{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    background-color: #009EAB;
}

@media(max-width : 575px){
    .welcomePage{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100vh;
        background-color: #009EAB;
    }
}