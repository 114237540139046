.googleSignIn{
    display: flex;
    justify-content: center;
    align-items: center;

    .googleSignInContainer{
        display: flex;
        width: 80%;
        height: 6vh;
        background-color: rgb(207, 202, 202);
        border-radius: 30px;
        justify-content: center;
        align-items: center;
        gap: 20px;
        cursor: pointer;
        color: rgb(72, 71, 71);

        img{
            width: 35px;
            height: 35px;
        }
    }
}