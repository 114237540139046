.bookingsWrapper {
  width: 100%;
  height: 100%;
  overflow: auto;
  max-height: 91vh;
  background-color: #f7f7f9;
  border-bottom-left-radius: 100px;
  border-bottom-right-radius: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding-bottom: 80px;

  .bookingHeading {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0;
    padding-top: 20px;
    background-color: #f7f7f9;
    padding-bottom: 20px;

    .bookingsHeader {
      font-size: 18px;
      font-weight: 500;
      color: #009eab;
      margin: 0;
      margin-left: 30px;
    }

    .bookingsCloseBtn {
      position: absolute;
      width: 30px;
      height: 30px;
      cursor: pointer;
      right: 10px;
    }
  }

  .bookingsContainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    border-bottom-left-radius: 55px !important;
    border-bottom-right-radius: 55px !important;
    // box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

    .bookings {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      background-color: #f7f7f9;
      max-height: 60vh;
      overflow: hidden;
      border-bottom-left-radius: 55px !important;
      border-bottom-right-radius: 55px !important;
      // padding-bottom: 50px;

      .bookingBody {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-top: 30px;

        .bookingTab {
          display: flex;
          width: 80%;
          justify-content: center;
          background-color: white;
          border: 2px solid #009eab;
          border-radius: 4px;
          margin-bottom: 30px;
          cursor: pointer;
        }
      }

      .bookingResetSaveBtn {
        width: 100%;
        display: flex;
        justify-content: center;
      }

      .resetBtn {
        display: flex;
        justify-content: center;
        width: 45%;
        border: 1px solid #009eab;
        color: #009eab;
        padding: 10px 0 10px 0;
        cursor: pointer;
        font-weight: 600;
      }

      .savebtn {
        display: flex;
        justify-content: center;
        width: 45%;
        background-color: #009eab;
        color: white;
        padding: 10px 0 10px 0;
        cursor: pointer;
        font-weight: 600;
      }
    }

    .bookings1 {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      // max-height: 90vh;
      // padding-bottom: 70px;
      background-color: #f7f7f9 !important;

      .bookingHeading {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin: 0;
        padding-top: 20px;
        background-color: #f7f7f9;

        .bookingsHeader {
          font-size: 18px;
          font-weight: 500;
          color: #009eab;
          margin: 0;
        }

        .bookingsCloseBtn {
          width: 30px;
          height: 30px;
          cursor: pointer;
          margin-right: 20px;
        }
      }

      .bookingBody {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        margin-top: 30px;

        .bookingTab {
          display: flex;
          width: 80%;
          justify-content: center;
          background-color: white;
          border: 2px solid #009eab;
          border-radius: 4px;
          margin-bottom: 30px;
          cursor: pointer;
        }
      }

      .bookingResetSaveBtn {
        width: 100%;
        display: flex;
        justify-content: center;
      }

      .resetBtn {
        display: flex;
        justify-content: center;
        width: 45%;
        border: 1px solid #009eab;
        color: #009eab;
        padding: 10px 0 10px 0;
        cursor: pointer;
        font-weight: 600;
      }

      .savebtn {
        display: flex;
        justify-content: center;
        width: 45%;
        background-color: #009eab;
        color: white;
        padding: 10px 0 10px 0;
        cursor: pointer;
        font-weight: 600;
      }
    }

    .bottomNav {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      z-index: 1000;
      background-color: white !important;
    }

    .filterSelectionContainer1 {
      width: 100%;
      height: 8%;
      background-color: #f7f7f9;
      // background-color: red;
      margin: 0;
      padding: 0;
      // overflow: hidden;
      border-bottom-left-radius: 200px !important;
      border-bottom-right-radius: 200px !important;
      // box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

      .spinnerWrapper {
        width: 100%;
        position: fixed;
        bottom: 50px;
        left: 0;
      }

      .selectSizesWrapper {
        width: 100%;
        height: 40%;
        display: flex;
        position: fixed;
        bottom: 0;
        left: 0;
      }

      .nextBtn {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #009eab;
        color: white;
        font-size: 20px;
        font-weight: 500;
        padding-top: 15px;
        padding-bottom: 15px;
        cursor: pointer;
        margin: 0;
      }
    }

    .filterSelectionContainer {
      width: 100%;
      height: 100%;
      overflow: hidden;
      margin: 0;
      padding: 0;
      position: absolute;
      background-color: rgba(0, 0, 0, 0.3);

      .spinnerWrapper {
        width: 100%;
        height: 30%;
        position: fixed;
        bottom: 50px;
        left: 0;
        right: 0;
      }

      .selectSizesWrapper {
        width: 100%;
        height: 40%;
        display: flex;
        position: fixed;
        bottom: 0;
        left: 0;
      }

      .brandWrapper {
        width: 100%;
        height: 50%;
        display: flex;
        position: fixed;
        bottom: 0;
        left: 0;
      }

      .nextBtn {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #009eab;
        color: white;
        font-size: 20px;
        font-weight: 500;
        padding-top: 15px;
        padding-bottom: 15px;
        cursor: pointer;
        margin: 0;
      }
    }
  }

  .bookingTableTabWrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    .dropDownPopUp {
      display: flex;
      width: 80%;
      justify-content: center;
      font-size: 13px;
      margin-top: -30px;
    }

    .no-line-table {
      width: 100%;
      border-collapse: collapse;
    }

    .no-line-table th,
    .no-line-table td {
      text-align: left;
      padding: 10px;
    }

    .no-line-table td {
      background-color: white;
    }

    .no-line-table th {
      border: none;
      background-color: rgb(221, 216, 216);
      font-weight: 400;
    }

    .actionIcons {
      display: flex;
      // gap: 20px;
      justify-content: center;

      img {
        width: 20px;
        height: 20px;
        cursor: pointer;
      }
    }
  }

  .bookingTableTab {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 80%;
    justify-content: center;
    background-color: #009eab;
    color: white;
    font-weight: 500;
    border-radius: 4px;
    margin-bottom: 30px;
    cursor: pointer;
    position: relative;


    p {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 250px;
    }

    .dropDown-Arrow {
      position: absolute;
      top: 20px;
      right: 10px;
      img {
        width: 20px;
        height: 20px;
        transform: rotate(0deg);
        transition: transform 0.3s ease;
      }
    }

    .dropDown-Arrow1 {
      position: absolute;
      top: 20px;
      right: 10px;

      img {
        width: 20px;
        height: 20px;
        transform: rotate(180deg);
        transition: transform 0.3s ease;
      }
    }
  }
}
