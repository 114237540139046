.homeScreenContainer {
  width: 100% !important;
  height: 100vh;
  display: flex;
  flex-direction: column;
  margin: 0px !important;
  padding: 0px !important;
  box-sizing: border-box !important;


  .homeScreen {
    width: 100%;
    background-color: #f7f7f9;
    border-bottom-left-radius: 55px;
    border-bottom-right-radius: 55px;
    padding-bottom: 75px;
    flex: 1;
    overflow-y: auto;
    padding-bottom: 40px;
    margin: 0;
    box-sizing: border-box;
    

    .locationContainer {
      display: flex;
      margin: 0;
      align-items: center;
      gap: 4px;
      align-items: center;
      margin-left: 10px;

      .locationIcon {
        width: 25px;
        height: 25px;
        margin-top: -15px;
      }
      .locationInfo {
        display: flex;
        flex-direction: column;
        margin: 0;
        height: 100px;
        padding: 0;
        font-size: 12px;

        .locationInfoHeading {
          margin: 0;
          padding: 0;
          font-size: 15px;
          font-weight: 600;
        }

        .locationHeader {
          display: flex;
          align-items: center;
          margin: 0;
          height: 50px;
          padding: 0;
          margin-top: 10px;
          margin-bottom: -10px;

          .downArrow {
            width: 25px;
            height: 25px;
            cursor: pointer;
          }
        }

        .locationDetails {
          margin: 0;
          padding: 0;
          margin-top: -5px;
        }
      }
    }

    .searchBar {
      position: relative;

      .searchbarContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;

        .searchBarInput {
          width: 80%;
          height: 4vh;
          border-radius: 4px;
          border-width: 1px;
          border: 1px solid #d3d3d3;
          font-size: 15px;

          &:focus {
            outline: none;
            border-color: #888;
          }

          &::placeholder {
            padding-left: 5px;
            color: #888;
          }
        }

        .homeSearchIcon{
          position: absolute;
          right: 80px;
          width: 25px;
          height: 25px;
          cursor: pointer;
        }

        .micIcon {
          position: absolute;
          right: 45px;
          width: 25px;
          height: 25px;
          cursor: pointer;
        }
      }
    }

    .videoContainer {
      display: flex;
      margin-top: 20px;
      justify-content: center;

      img {
        width: 90%;
      }
    }

    .poolDetails {
      display: flex;
      margin-left: 50px;
      font-weight: 600;
      margin-top: 40px;
      width: 90%;
      box-sizing: border-box;
    }

    .poolDetailsContainer {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 30px;
      justify-content: center;
      align-items: stretch;
      box-sizing: border-box;

      .poolBoxContainer{
        position: relative;
        width: 90vw;
        display: grid; 
        place-items: center;
        margin-left: 5vw;
        box-sizing: border-box;
  
        .poolBox {
          display: flex;
          width: 100%;
          justify-content: center;
          align-items: center;
          border-radius: 20px;
  
  
          .poolDetailsImage {
            margin: 0;
            padding: 0;
            border: none;
            margin-left: 10px;
            width: 30vw;
          }
        }

      }

      .favouriteContainer {
        position: absolute;
        top: 10px;
        right: 2.5vw;

        img {
          width: 25px;
          height: 25px;
          cursor: pointer;
        }
      }

      .typesContainer {
        width: 90%;
        position: absolute;
        bottom:  3vw;
        left: 4vw;
        background-color: #ffffff;
        margin: 0;
        border-radius: 4px;
        padding-left: 4px;
        padding-bottom: 5px;
        height: 30px;
        padding-top: 5px;
        padding-bottom: 5px;

        .poolTypes {
          font-size: 13px;
          margin: 0;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 400px;
          font-weight: 600;
        }

        .star {
          display: flex;
          padding: 0;
          height: fit-content;

          img {
            width: 12px;
            height: 12px;
            margin: 0;
            padding-left: 3px;
          }
        }
      }
    }

    .bottomNav {
      position: fixed;
      bottom: 0 !important;
      left: 0;
      width: 100%;
      z-index: 1000;
    }
  }
}