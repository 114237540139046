/* CustomAlert.scss */
.customAlert {
    width: 80%;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    z-index: 9999;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .alertContent {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .buttonWrapper {
    display: flex;
    gap: 10px;
    margin-top: 20px;
  }
  
  .yesButton, .noButton {
    padding: 10px 40px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .yesButton {
    background-color: #009eab;
    color: white;
  }
  
  .noButton {
    background-color: white;
    color: #009eab;
    border: 1px solid #009eab;
  }
  