.poolDetailsTabWrapper {
  display: flex;
  width: 90%;

  .poolDetailsTab {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    border: 1px solid #f2f2f2;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.06);
    border-radius: 8px;
    background-color: #f9f9f9;
    margin-bottom: 10px;

    p {
      padding-left: 20px;
      color: #009eab;
    }

    img {
      width: 30px;
      padding-right: 10px;
      cursor: pointer;
    }
  }

  .poolDetailsTabOpened{
    width: 100%;
    border: 1px solid #f2f2f2;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.06);
    border-radius: 8px;
    background-color: #F2F2F2;
    margin-bottom: 10px;
    padding: 10px;


    .openTabHeader{
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      justify-content: space-between;
  
      p {
        padding-left: 0px;
        color: #009eab;
      }
  
      img {
        width: 30px;
        padding-right: 10px;
        cursor: pointer;
        transform: rotate(180deg);
      };
    }

    .tabDetails{
      display: flex;
      justify-content: space-between;
      margin: 0;
      padding: 0px;

      .tabDetailsHeadings{
        margin: 0;
        padding: 4px;
        font-size: 15px;
        font-weight: 500;
      }

      .tabDetailsValue{
        margin: 0;
        padding: 4px;
        font-size: 15px;
      }
    }
  }
}
