.bookingDetailsContainer {
    width: 100%;
    height: 100vh;
    background-color: white !important;

  .bookingDetails {
    width: 100%;
    height: calc(100vh - 60px);
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white !important;

    h1 {
      font-size: 30px;
      font-weight: 600;
      padding: 20px 0 30px 0;
    }


    .bookingTabContainer{
      display: flex;
      background-color: #f7f7f9;
      flex-direction: column;
      align-items: center;
      width: 100%;
      height: 71vh;
      max-height: calc(100% - 80px); 
      overflow-y: auto;
      padding-bottom: 50px;
      border-bottom-left-radius: 55px;
      border-bottom-right-radius: 55px;
      // box-shadow: 0 8px 12px rgba(0, 0, 0, 0.09);

    .bookedTab {
      width: 95%;
      height: 14vh;
      display: flex;
      justify-content: center;
      margin-bottom: 20px;

      .wrapperComponent {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        gap: 15px;
        border: 1px solid #f7f7f9;
        box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.1);
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;

        img {
          display: flex;
          width: 80px;
          height: 80px;
          margin-top: 3.5vw;
        }

        .bookedPoolInfo {
          display: flex;
          flex-direction: column;

          .bookedPoolInfo {
            font-size: 17px;
            font-weight: 500;
          }

          .bookedPoolInfoDetails {
            width: 100%;
            font-size: 13px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 190px;
          }
        }
      }

      .bookedDate {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background-color: #009eab;
        padding: 10px;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        color: white;
        font-weight: 500;

        p {
          display: flex;
          flex-direction: row;
          font-size: 27px;

          span {
            font-size: 27px;
          }

          sup {
            font-size: 12px;
            margin-right: 10px;
          }
        }
      }
    }
    }
  }

  .bottomNav {
    background-color: white !important;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
  }
}
