.confirmationWrapper{
    width: 100%;
    height: 89vh;
    display: flex;
    flex-direction: column;
    background-color: #f7f7f9 !important;
    border-bottom-left-radius: 55px;
    border-bottom-right-radius: 55px;
    // box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

    .confirmation{
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        border-bottom-left-radius: 40px;
        border-bottom-right-radius: 40px;


        .confirmationBackBtn{
            display: flex;
            padding: 20px 20px 20px 10px;

            img{
                width: 20px;
                height: 20px;
                cursor: pointer;
            }
        }

        .confirmationContainer{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border-bottom-left-radius: 40px;
            border-bottom-right-radius: 40px;

            .confirmationHeader{
                p{
                    font-size: 24px;
                    font-weight: 600;
                }
            }

            .expectedDate{
                p{
                    font-weight: 500;

                    span{
                        margin-left: 3px;
                        font-weight: 600;
                        color: #009eab;
                    }
                }
            }
        }
    }

    .bottomNav {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        z-index: 1000;
        background-color: white !important;
      }
}