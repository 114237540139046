.getStarted{
    background-color: #009EAB;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;

    .logoContainer{
        margin-top: 200px;
    }

    .buttonContainer{
        button{
            width: 80vw;
            height: 6vh;
            border-radius: 25px;
            border: none;
            cursor: pointer;
            font-size: 20px;
            font-weight: 700;
            color: #009EAB;
            background-color:white;
            margin-top: 150px;
        }
        p{
            color: white;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px;
        }
        span{
            font-weight: 600;
            cursor: pointer;
            text-decoration: underline;
        }
    }
}