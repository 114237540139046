.createAccount {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  padding-bottom: 40px;

  h2 {
    display: flex;
    margin: 50px 0 50px 20px;
    font-size: 30px;
  }

  .sigInFormField {
    margin-bottom: 40px;

    label {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .signInlabelHeading {
        text-align: left;
        width: 90vw;
        margin: 0;
        font-weight: 500;
      }

      .passwordContainer{
        position: relative;
      }

      .confirmPasswordContainer{
        position: relative;
      }

      input {
        display: flex;
        width: 90vw;
        height: 5vh;
        border-radius: 4px;
        border-width: 1px;
        border: 1px solid #d3d3d3;
        font-size: 15px;

        &:focus {
          outline: none;
          border-color: #888;
        }
      }
    }

    .eyeIcon{
      position: absolute;
      right: 15px;
      top: 10px;
      width: 25px;
      height: 25px;
      cursor: pointer;
    }

    .confirmEyeIcon{
      position: absolute;
      right: 15px;
      top: 10px;
      width: 25px;
      height: 25px;
      cursor: pointer;
    }
  }

  .termAndConditionsContainer {
    width: 100vw;

    .termsAndConditions {
      color: gray;
      font-size: 15px;
      display: flex;
      justify-content: center;
      gap: 5px;
      span {
        color: #596be6;
        cursor: pointer;
      }

      @media (max-width: 360px) {
        font-size: 13px;
      }
    }
  }

  .centerButtonWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    .signInButton {
      display: flex;
      width: 80%;
      justify-content: center;
      align-items: center;
      height: 6vh;
      border-radius: 30px;
      border: none;
      background-color: #009eab;
      color: white;
      font-size: 17px;
      font-weight: 500;
      cursor: pointer;
    }
  }

  .separator-container {
    display: flex;
    align-items: center;
    width: 100%;
    margin: 20px 0;
  }

  .line {
    flex-grow: 1;
    height: 1px;
    background-color: #d3d3d3; 
  }

  .separator-text {
    margin: 0 10px;
    font-size: 14px;
    color: #9e9e9e;
    text-align: center;
  }

  .navigateToLogIn{
    color: gray;
    display: flex;
    justify-content: center;
    margin-top: 50px;
    padding-bottom: 30px;
    gap: 5px;

    span{
      color: #596be6;
      cursor: pointer;
    }
  }

  .error{
    margin: 0;
    color: red;
    font-size: 14px;
    margin-left: 20px;
  }
}
