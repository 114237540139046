.merchantDashBoard{
    width: 100%;
    height: 91vh;
    background-color: #f7f7f9;
    border-bottom-left-radius: 100px;
    border-bottom-right-radius: 100px;

    .header{
        display: flex;
        justify-content: space-between;
        padding: 20px;
    }

    .bookingsTrackerTab{
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: center;
        align-items: center;

        .newBookingsTab,
        .activeServices,
        .completedServices{
            display: flex;
            justify-content: space-between;
            padding: 20px;
            align-items: center;
            width: 80%;
            height: 10vh;
            background-color: #009EAB; 
            color: white;   
            margin-bottom: 20px;
            border-radius: 7px;
            cursor: pointer;

            p{
                width: 60%;
                font-size: 20px;
            }

            h1{
                font-size: 45px;
            }
        }
    }
}

.DashBoardbottomNavbar{
    position: absolute;
    bottom: 0;
    width: 100%;
    background-color: white;
}