.newBookings {
  width: 100%;
  height: 91vh;
  overflow: auto;
  max-height: 91vh;
  background-color: #f7f7f9;
  border-bottom-left-radius: 100px;
  border-bottom-right-radius: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding-bottom: 50px;

  .newBookingHeader {
    width: 100%;
    display: flex;
    align-items: center;
    padding-top: 20px;
    padding-left: 20px;
    gap: 20px;
    box-sizing: border-box;

    .newBookingsBack {
      width: 25px;
      height: 25px;
      cursor: pointer;
    }

    .searchBar {
      width: 100%;
      position: relative;

      .searchbarContainer {
        display: flex;
        // justify-content: center;
        align-items: center;
        width: 100%;

        .searchBarInput {
          width: 90%;
          height: 4vh;
          border-radius: 4px;
          border-width: 1px;
          border: 1px solid #d3d3d3;
          font-size: 15px;

          &:focus {
            outline: none;
            border-color: #888;
          }

          &::placeholder {
            padding-left: 5px;
            color: #888;
          }
        }

        .homeSearchIcon {
          position: absolute;
          right: 70px;
          width: 25px;
          height: 25px;
          cursor: pointer;
        }

        .micIcon {
          position: absolute;
          right: 35px;
          width: 25px;
          height: 25px;
          cursor: pointer;
        }
      }
    }
  }

  .newBookingsBox {
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 20px;
    box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.1); 

    .newBookingsContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: white;
      justify-content: space-between;
      padding: 20px;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;

      .newBookingsDetailsWrapper {
        display: flex;
        width: 100%;
        justify-content: space-between;

        .newBookingsDetails {
          display: flex;
          flex-direction: column;
          margin: 0;

          .newBookingsPool {
            font-weight: 500;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 250px;
            margin: 0;
          }

          .newBookingsAddress {
            margin: 10px 0 0 0;
            font-size: 13px;
            max-width: 250px;
          }
        }

        .newBookingsDate {
          .date {
            margin: 0;
            font-size: 30px;
            font-weight: 600;
            color: #009eab;

            .powerOf {
              margin: 0;
              font-size: 17px;
              font-weight: lighter;
            }
          }

          .month {
            margin: -10px 0 0 0;
            font-weight: 600;
            color: #009eab;
          }
        }
      }
    }

    .newBookingsBtnContainer {
      display: flex;

      .specificationBtn {
        width: 50%;
        display: flex;
        font-size: 14px;
        border: 1px solid #009eab;
        background-color: white;
        margin: 0;
        justify-content: center;
        align-items: center;
        border-bottom-left-radius: 4px;
        padding: 10px;
        color: #009eab;
        font-weight: 500;
        cursor: pointer;
      }

      .assignBtn {
        width: 50%;
        display: flex;
        font-size: 14px;
        justify-content: center;
        align-items: center;
        background-color: #009eab;
        color: white;
        border-bottom-right-radius: 4px;
        padding: 10px;
        font-weight: 500;
        cursor: pointer;
      }
    }
  }

//   .newBookingsBox:last-child {
//     margin-bottom: 50px; 
//   }
  

  .newBookingsbottomNavbar {
    position: absolute;
    bottom: 0;
    width: 100%;
    background-color: white;
  }
}
