.bottomNavBar {
  display: flex;
  width: 100%;
  border: none !important;

  .leftNavTabs,
  .rightNavTabs {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;

    .sideTab {
      width: 100px;
      height: 75px;
      cursor: pointer;
      margin: 0;
      padding: 0;
    }
  }

  .leftNavTabs{
    padding-right: 20px;
    padding-left: 0;
    margin-left: 0;
  }

  .rightNavTabs{
    padding-left: 20px;
    padding-right: 0;
    margin-right: 0;
  }

  .chatContainer {
    display: flex;
    justify-content: center; 
    align-items: center;
    position: absolute; 
    left: 50%;
    bottom: -5px;
    transform: translateX(-50%);

    .snChatTab {
      z-index: 999;
      height: 150px; 
      width: 150px; 
      cursor: pointer;
    }
  }
  
  .backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); 
    z-index: 999; 
  }
  
  .alertPopUp {
    position: absolute; 
    z-index: 1000; 
  }
}
