.inProcess {
  width: 100%;
  height: 91vh;
  overflow: auto;
  max-height: 91vh;
  background-color: #f7f7f9;
  border-bottom-left-radius: 100px;
  border-bottom-right-radius: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding-bottom: 50px;

  .inProcessHeader {
    width: 100%;
    display: flex;
    align-items: center;
    padding-top: 20px;
    padding-left: 20px;
    gap: 20px;
    box-sizing: border-box;

    .inProcessBack {
      width: 25px;
      height: 25px;
      cursor: pointer;
    }

    .searchBar {
      width: 100%;
      position: relative;
      box-sizing: border-box;

      .searchbarContainer {
        display: flex;
        // justify-content: center;
        align-items: center;
        width: 100%;

        .searchBarInput {
          width: 90%;
          height: 4vh;
          border-radius: 4px;
          border-width: 1px;
          border: 1px solid #d3d3d3;
          font-size: 15px;

          &:focus {
            outline: none;
            border-color: #888;
          }

          &::placeholder {
            padding-left: 5px;
            color: #888;
          }
        }

        .inProcessIcon {
          position: absolute;
          right: 70px;
          width: 25px;
          height: 25px;
          cursor: pointer;
        }

        .micIcon {
          position: absolute;
          right: 35px;
          width: 25px;
          height: 25px;
          cursor: pointer;
        }
      }
    }
  }

  .inProcessContainer{
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 20px;
    box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.1); 
    background-color: white;
    border-radius: 7px !important;


    .inProcesPoolDetail{
        display: flex;
        justify-content: space-between;

        .inProcessNameAddress{
            .inProcessName{
                padding-top: 10px;
                padding-left: 10px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 240px;
                font-size: 16px;
                font-weight: 600;
                margin: 0;
            }

            .inProcessAddress{
                padding-left: 10px;
                width: 70vw;
                font-size: 12px;
            }
        }

        .inProcessSchedule{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding-right: 10px;

            .inProcessDate{
                margin: 0;
                padding: 0;
                font-size: 30px;
                font-weight: 600;
                color: #009eab;

                .inProcessPower{
                    margin: 0;
                    font-size: 17px;
                    font-weight: lighter;
                }
            }

            .inProcessMonth{
                margin: 0;
                padding: 0; 
                margin: -10px 0 0 -5px;
                font-size: 20px;
                font-weight: 600;
                color: #009eab;
            }
        }
    }

    .inProcessCompleteBtn{
        display: flex;
        justify-content: center;
        padding: 10px 0 10px 0;
        background-color: #009eab;
        color: white;
        font-weight: 500;
        cursor: pointer;
        border-bottom-left-radius: 7px;
        border-bottom-right-radius: 7px;
    }
  }

  .inProcessBottomNavBar {
    position: absolute;
    bottom: 0;
    width: 100%;
    background-color: white;
  }
}
