.poolDetailsBox {
  width: 100% !important;
  height: 100vh;
  display: flex;
  flex-direction: column;
  margin: 0px !important;
  padding: 0px !important;

  .poolDetails {
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    background-color: #f7f7f9;
    border-bottom-left-radius: 55px;
    border-bottom-right-radius: 55px;
    // box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding-bottom: 60px;
    width: 100%;
    flex: 1;
    overflow-y: auto;
    margin: 0;

    .nativeBack {
      width: 20px;
      height: 20px;
      cursor: pointer;
      margin-bottom: 20px;
      margin-left: 10px;
    }

    .detailsHeader {
      margin-left: 15px;
      padding-top: 15px;
      .star {
        display: flex;
        img {
          width: 15px;
          height: 15px;
        }
      }
    }

    .aboutcontainer {
      padding-top: 30px;
      margin-left: 15px;
      width: 90%;

      .aboutHeader {
        font-size: 15px;
        font-weight: 600;
      }

      .aboutDetails {
        display: flex;
        justify-content: center;
        width: 100%;
        font-size: 13px;
      }
    }

    .specifyBtn {
      width: 80%;
      display: flex;
      flex-direction: row;
      gap: 20px;
      border: 1px solid #009eab;
      justify-content: center;
      align-items: center;
      margin-left: 10vw;
      cursor: pointer;

      .arrowImg {
        width: 30px;
        height: 30px;
      }

      .dropDownText {
        font-size: 16px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;

        @media (max-width: 400px) {
          font-size: 13px;
        }
      }
    }

    .bookSlotsBtn {
      display: flex;
      justify-content: center;
      width: 100%;

      p {
        width: 50%;
        background-color: #009eab;
        display: flex;
        justify-content: center;
        padding: 10px 0 10px 0;
        border-radius: 4px;
        font-weight: 600;
        color: white;
        cursor: pointer;
      }
    }

    .bottomNav {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      z-index: 1000;
    }
  }

  .estimateText {
    display: flex;
    justify-content: center;
    font-size: 20px;
    color: #009eab;
    margin: 0;
    padding: 0;
    margin-top: 10px;
  }

  .slotPopUpWrapper{
    width: 100%;
    position: absolute;
    background-color: #f7f7f9;
    bottom: 75px;
    padding-bottom: 70px;
    border-top-left-radius: 40px;
    border-top-right-radius: 40px;
    border-bottom-left-radius: 40px;
    border-bottom-right-radius: 40px;

    .slotPopUp {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-top-left-radius: 55px;
      border-top-right-radius: 55px;
      box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.1); 

      .dragBar{
        margin-top: 10px;
        width: 40%;
        height: 4px;
        border-radius: 5px;
        background-color: gray;
        margin-bottom: 20px;
      }

      h3{
        margin-bottom: 20px;
        font-size: 19px;
        font-weight: 600;
      }

      .dateWrapper{
        display: flex;
        justify-content: center;
        flex-direction: column;
        width: 80%;
        background-color: #009eab;
        border-radius: 10px;
        padding: 10px 10px;


        .weekContainer{
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            font-size: 14px;
            color: white;
            font-weight: 600;
        }

        .filters{
          width: 100%;
          display: flex;
          justify-content: center;
        }

        .dateComponent {
          width: 100%;
          display: grid;
          grid-template-columns: repeat(7, 1fr); 
          color: white;
          font-weight: 500;
    
          .day {
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;
            height: 24px;
            width: 20px;
            padding: 3px 10px 3px 10px;
          }
    
          .selectedDay{
            display: flex;
            height: 24px;
            width: 20px;
            justify-content: center;
            align-items: center;
            padding: 3px 10px 3px 10px;
            margin-left: 7px;
            cursor: pointer;
            background-color: white;
            color: #009eab;
            border-radius: 20px;
            width: 10px;
          }
        }
    
      }
  
      .confirmBtn{
        width: 80%;
        display: flex;
        justify-content: center;
        background-color: #009eab;
        color: white;
        font-weight: 500;
        margin-top: 40px;
        border-radius: 4px;
        cursor: pointer;
      }
    }
  }

  .monthContainer{
    position: absolute;
    background: white;
    border: 1px solid #ddd;
    padding: 10px;
    z-index: 1000;
    width: 74%;
    height: 50%;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    padding: 20px;
    justify-content: center;
    align-items: center;
    background-color: #009eab;
    color: white;
    border: none;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    left: 8vw;
  }

  .yearContainer{
    position: absolute;
    background: white;
    border: 1px solid #ddd;
    padding: 10px;
    z-index: 1000;
    width: 74%;
    height: 50%;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    padding: 20px;
    justify-content: center;
    align-items: center;
    background-color: #009eab;
    color: white;
    border: none;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    left: 8vw;
  }

  .monthPopup, .yearPopup {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    height: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  
  .monthOption, .yearOption {
    padding: 5px;
    cursor: pointer;
    margin: 0;
  }
  
  .monthOption:hover, .yearOption:hover {
    color: #009eab;
    background-color: #f7f7f9;
  }
  
  .monthSelect, .yearSelect {
    padding: 10px;
    cursor: pointer;
    display: inline-block;
    color: white;
    font-weight: 600;

    span{
      margin: 0;
    }
  }
  
  .filters {
    margin: 0;
  }
  
  .dateComponent p {
    cursor: pointer;
    padding: 5px;
  }
  
  .selectedDay {
    background-color: #0066cc;
    color: white;
  }

  .backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); 
    z-index: 999; 
  }
  
  .alertPopUp {
    position: absolute; 
    z-index: 1000; 
  }
}


